/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductList {
    &-PaginationButtons {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include small-desktop {
            flex-direction: row;
        }

        &_isVisible {
            display: flex;
        }
    }

    &-LoadProductsButton {
        padding: 11px 15px;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;

        @include small-desktop {
            width: 30%;
            padding: 15px;
        }

        &_type {
            &_loadMore,
            &_loadAll {
                font-size: var(--font-size-normal-small);
                margin: 0 12px;

                @include desktop {
                    font-size: var(--font-size-medium);
                }

                &:hover {
                    background-color: var(--color-red-dark);
                }
            }

            &_loadMore {
                border: 1px solid var(--color-red);
                color: var(--color-red);
                margin-block-end: 23px;

                @include small-desktop {
                    margin-block-end: 0;
                }

                &:hover {
                    color: var(--color-white);
                    text-decoration: none;
                }
            }
        }
    }

    &-Loader,
    &-PaginationButtons {
        margin-block-start: 12px;

        @include small-desktop {
            margin-block-start: 45px;
        }
    }

    &-LoadButton {
        background-color: var(--color-black);
        font-size: var(--font-size-normal-small);
        padding: var(--button-padding);
        width: 100%;
        margin: 22px 0;

        @include small-desktop {
            width: 30%;
        }

        @include desktop {
            margin: 22px auto;
            display: block;
            font-size: var(--font-size-medium);
        }
    }
}
