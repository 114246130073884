/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.product-group-features {
    margin-block-start: 4rem
}

@media only screen and (min-width: 1025px) {
    .product-group-features {
        margin-inline-end: 3rem;
        margin-inline-start: 3rem
    }
}

.product-group-features__container {
    display: flex
}

@media only screen and (min-width: 1025px) {
    .product-group-features__container {
        flex-wrap: wrap;
        justify-content: space-between
    }
}

@media (max-width: 1024px) {
    .product-group-features__container {
        padding-inline-start: 3.7rem;
        padding-inline-end: 3.7rem;
        margin-block-end: 4rem
    }
}

.product-group-features__item {
    text-align: center
}

@media only screen and (min-width: 1025px) {
    .product-group-features__item {
        margin-block-end: 4rem;
        width: 32%
    }
}

@media (max-width: 1024px) {
    .product-group-features__item {
        margin-inline-start: .8rem;
        margin-inline-end: .8rem
    }
}

.product-group-features__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 24px 'FFDIN', Arial, sans-serif;
    font: 2.4rem 'FFDIN', Arial, sans-serif;
    line-height: 1;
    margin-block-end: 1rem;
    padding: 0 2rem
}

@media only screen and (min-width: 1025px) {
    .product-group-features__title {
        margin-block-start: 2rem
    }
}

@media (max-width: 1024px) {
    .product-group-features__title {
        text-transform: uppercase;
        line-height: 1
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product-group-features__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN', Arial, sans-serif;
        font: 1.8rem 'FFDIN', Arial, sans-serif
    }
}

@media (max-width: 767px) {
    .product-group-features__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 17px 'FFDIN', Arial, sans-serif;
        font: 1.7rem 'FFDIN', Arial, sans-serif
    }
}

.product-group-features__text {
    font: 15px 'FFDIN', Arial, sans-serif;
    font: 1.5rem 'FFDIN', Arial, sans-serif;
    line-height: 1.35;
    padding: 0 2rem
}

@media (max-width: 767px) {
    .product-group-features__text {
        font: 14px 'FFDIN', Arial, sans-serif;
        font: 1.4rem 'FFDIN', Arial, sans-serif
    }
}

.product-group-features__img {
    display: block;
    width: 100%
}

@media (max-width: 1024px) {
    .product-group-features__img {
        padding-block-end: 1.5rem;
        margin-block-end: 1.25rem;
        border-bottom: .1rem solid #e1e1e1
    }
}
