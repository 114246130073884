/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

:root {
    --add-to-cart-btn-height: 36px;
    --add-to-cart-btn-padding: 1rem;
}

.ProductActions {
    flex-direction: column;

    @include desktop { // for style issues fixes on tablet resolution
        padding-inline-start: 10px;
    }

    @include mobile {
        border-bottom: 1px solid var(--color-input-border);
        margin-block-end: 1px;
    }

    &-ProductLabel {
        &Mobile {
            @include desktop {
                display: none;
            }
        }

        &Desktop {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    &-PriceWrapper {
        min-height: unset;
        height: fit-content;

        @include mobile {
            padding-inline-start: 0;
            padding-block-start: 0;
            padding-block-end: 0;
        }

        data {
            span {
                font-family: var(--font-FFDIN);
                font-weight: bold;
                font-size: 21px;

                @include isArabic {
                    font-family: var(--font-DINArabic);
                }

                @include mobile {
                    font-size: 22px;
                }
            }
        }
    }

    &-ProductGallery {
        @include mobile {
            padding-inline-end: 2.3rem;
            padding-inline-start: 2.3rem;
        }
    }

    &-TierPrices {
        order: unset;
    }

    &-MobileInfoBlock {
        display: block;

        @include desktop {
            display: none;
        }

        .ProductActions-PriceSkuWrapper {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }

        .ProductActions-Section_type_sku,
        .ProductActions-Section_type_name {
            display: block;
        }
    }

    &-PriceSkuWrapper {
        display: none;

        @include tablet {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
        }

        @include desktop {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-block-end: 1.8rem;
        }
    }

    &-WidgetsWrapper {
        display: flex;
        flex-direction: column;

        >div {
            margin-block-end: 5px;
        }
    }

    &-Attributes {
        padding-inline-start: 0;

        .ExpandableContent {
            border: none;
        }

        .ExpandableContent-Button {
            @include mobile {
                padding: 0;
                pointer-events: none;
            }

            &::before,
            &::after {
                display: none;
            }
        }

        .ExpandableContent-Content {
            @include mobile {
                padding: 0;
            }
        }

        .ProductAttributeValue-Text {
            &:first-of-type {
                @include mobile {
                    padding-inline-start: 0;
                }
            }
        }

        .FieldSelect {
            &-Select {
                @include mobile {
                    font-size: var(--font-size-small);
                }
            }
        }

        .Field_type_select {
            @include mobile {
                margin: 0;
            }
        }
    }

    &-AttributesWrapper {
        order: unset;
    }


    &-AddToCartWrapper {
        display: flex;
        flex-wrap: unset;
        order: unset;
        margin: 0;
        margin-block-start: 1.1rem;
        width: 100%;

        @include before-desktop {
            padding: var(--add-to-cart-btn-padding);
        }

        @include mobile {
            justify-content: space-between;
            position: fixed;
            z-index: 100;
            inset-block-end: 0;
            inset-inline-start: 0;
            box-shadow: .1rem 0 .5rem rgba(0, 0, 0, .5);
            background-color: white;
        }

        .ProductWishlistButton {
            &-Button {
                @include mobile {
                    width: 50px;
                }
            }

            @include after-mobile {
                margin: 0;
            }
        }

        .ProductActions-AddToCart {
            margin-inline-end: 5px;

            &.Button {
                background-color: #ae946d;
                border-color: #ae946d;

                &:hover {
                    background-color: #b7a07d;
                    border-color: #b7a07d;
                }
            }

            @include after-mobile {
                margin-inline-end: 8px;
                order: unset;
                margin-block-start: 0;
            }
        }
    }

    &-AddToCart {
        background: var(--color-red);
        border-color: var(--color-red);
        width: 100%;

        &.AddToCart_isPlaceholder {
            @include placeholder-animation-with-padding;
        }

        @include mobile {
            margin-block-start: 0;
            height: var(--add-to-cart-btn-height);
            padding: 0;
            margin-inline-end: 5px;
        }

        @include after-mobile {
            /* stylelint-disable-next-line declaration-no-important */
            padding: var(--button-padding) !important;
        }

        &.Button {
            &:hover {
                background: var(--color-red-dark);
                border-color: var(--color-red-dark);
            }
        }

        span {
            font-family: var(--font-FFDIN);
            font-size: var(--font-size-medium);
            font-weight: bold;

            @include isArabic {
                font-family: var(--font-DINArabic);
            }

            @include mobile {
                font-size: var(--font-size-small);
            }
        }
    }

    &-AddToCartFixed {
        @include mobile-bottom-wrapper {
            inset-block-end: 0;
            /* stylelint-disable-next-line declaration-no-important */
            padding-block-end: 12px !important;
            box-shadow: .1rem 0 .5rem rgba(0,0,0,.5);
        }

        .ProductActions-AddToCart {
            margin-inline-end: 5px;

            &.Button {
                background-color: #ae946d;
                border-color: #ae946d;

                &:hover {
                    background-color: #b7a07d;
                    border-color: #b7a07d;
                }
            }
        }

        .ProductWishlistButton {
            &-Button {
                height: var(--add-to-cart-btn-height);
                width: 50px;
            }
        }
    }

    &-Title {
        font-size: 26px;

        @include mobile {
            line-height: 1;
            padding-block-end: 16px;
        }

        @include desktop {
            font-size: 31px;
            line-height: 3rem;
        }
    }

    &-ColorAttributeWrapper {
        @include mobile {
            margin-block-end: 10px;
        }

        .ProductActions-Attributes {
            padding: 0;
        }

        .ProductConfigurableAttributes {
            &-Expandable {
                margin: 0;

                .ExpandableContent-Button {
                    display: none;
                }
            }

            &-SwatchList {
                @include mobile {
                    padding-block-start: 3px;
                }

                &_isInvalid {
                    border: 1px solid var(--color-red);
                }
            }

            &-ColorSubLabel {
                display: flex;

                &Key {
                    font-family: var(--font-FFDIN);
                    font-weight: bold;
                    margin-inline-end: 3px;

                    @include mobile {
                        font-size: var(--font-size-medium);
                    }

                    @include isArabic {
                        font-family: var(--font-DINArabic);
                    }
                }

                &Value {
                    @include mobile {
                        font-size: var(--font-size-medium);
                    }
                }
            }
        }

        .ProductAttributeValue {
            &-Image {
                border-radius: 0;
                width: 27px;
                height: 27px;
                min-height: 27px;
                box-shadow: inset 0 0 0 1px var(--color-gray-nero);

                &-Overlay {
                    &::after {
                        display: none;
                    }

                    &::before {
                        border-radius: 0;
                        width: 33px;
                        height: 33px;
                    }
                }
            }
        }
    }

    .ExpandableProductInfo {
        &-ExpandableContentButton {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 10px 40px 10px 0;
            border-bottom: 1px solid $c-gray-c;

            @include isArabic {
                padding-inline-start: 0;
            }

            &::before {
                display: none;
            }

            &::after {
                content: url('../../../public/assets/images/icons/right-black-icon.svg');
                inset-inline-end: 0;
                background: transparent;
                width: 18px;
                height: 18px;
            }

            &.ExpandableContent-Button_isContentExpanded {
                &::after {
                    transform: rotate(270deg);
                }
            }

            @include mobile {
                margin-block-end: 8px;
                border-bottom: 1px solid #eee;
            }
        }

        &-ExpandableContentHeading {
            font-size: 18px;
            font-weight: 300;

            @include isArabic {
                direction: ltr;
            }
        }

        &-ExpandableContentContent {
            max-height: 0;
            overflow: hidden;
            margin: 0;
            opacity: 0;

            &_isContentExpanded {
                @include expanded-content;

                padding-block-end: 25px;
                margin: 0 -15px 0 0;
                max-height: 370px;
                padding: 15px 15px 0 0;

                @include mobile {
                    margin-block-end: 30px;
                }
            }

            ul {
                margin-block-start: 16px;

                li {
                    padding-inline-start: 1.5rem;

                    &::before {
                        inset-inline-start: 0;
                    }
                }
            }
        }
    }

    &-SizeAttributeWrapper {
        @include after-mobile {
            margin-block-end: 5px;
        }

        .FieldSelect-Options {
            display: none;
            width: 150%;

            @include desktop {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                border: none;
                inset-block-start: 48px;
                background-color: transparent;

                @include isArabic {
                    inset-inline-end: 0;
                }
            }

            &_isExpanded {
                overflow: initial;
            }
        }

        .FieldSelect-Option {
            display: block;
            padding-inline-start: 0;
            border: {
                inset-inline-end: 1px solid var(--color-input-border);
                inset-block-start: 1px solid var(--color-input-border);
                inset-block-end: 0;
            }

            &:nth-child(3n + 1) {
                border-left: 1px solid var(--color-input-border);
            }

            &:nth-last-child(-n+3) {
                border-bottom: 1px solid var(--color-input-border);
            }

            @include isArabic {
                padding-inline-end: 0;

                &:nth-child(n) {
                    padding-inline-end: 0;
                }

                &:nth-child(3n + 3) {
                    border-left: 1px solid var(--color-input-border);
                }

                &:nth-child(3n + 1) {
                    border-right: 1px solid var(--color-input-border);
                    border-left: 0;
                }

                &:last-child {
                    border-left: 1px solid var(--color-input-border);
                }
            }

            span {
                height: 100%;
                width: 100%;
                text-align: center;
            }
        }

        button {
            &:disabled {
                span {
                    background-color: #e1e1e1;
                    border-color: #b9b9b9;
                    color: #727272;
                    font-weight: 400;
                }
            }
        }

        .ProductAttributeValue {
            &-String {
                border-radius: 0.125rem;
                font-size: var(--font-size-medium-small);
                height: 60px;
                width: 60px;

                &::after {
                    content: none;
                }

                &_isSelected {
                    background-color: var(--color-black);
                    color: var(--color-white);
                    font-weight: 400;
                }
            }
        }

        .ProductConfigurableAttributes {
            &-SwatchList {
                gap: 35px;
                margin-block-end: 35px;
            }
        }
    }

    &-Qty {
        height: fit-content;
        width: 100%;

        &-Disabled {
            pointer-events: none;

            .FieldSelect {
                &-Select {
                    background-color: var(--color-grey-c);
                }
            }
        }

        .FieldSelect {
            &-Select {
                @include desktop {
                    padding-inline-start: 20px;
                }

                @include mobile {
                    font-size: var(--font-size-small);
                }
            }

            &-Option {
                border: 1px solid var(--color-input-border);

                &:not(:first-of-type) {
                    border-top: none;
                }

                &:last-of-type {
                    border-bottom: 1px solid var(--color-input-border);
                }
            }

            &-Options {
                &_isExpanded {
                    @include mobile {
                        border: 0;
                    }
                }
            }
        }

        @include mobile {
            display: block;
        }
    }

    &-Schema {
        order: unset;
    }

    &-Sku {
        height: fit-content;
        color: var(--color-black);
        font-family: var(--font-FFDIN);
        font-size: 14px;
        margin-inline-end: 2px;

        @include isArabic {
            font-family: var(--font-DINArabic);
        }

        @include mobile {
            font-size: 12px;
        }

        &::after {
            display: none;
        }
    }

    &-Stock {
        height: fit-content;
        font-family: var(--font-FFDIN);
        font-size: var(--font-size-small);
        color: var(--color-input-label);
        display: flex;
        margin-block-start: 10px;
        flex-grow: 4;

        @include isArabic {
            font-family: var(--font-DINArabic);
        }

        @include after-mobile {
            margin-block-start: 0;
        }

        @include desktop {
            font-size: var(--font-size-normal-small);
        }

        &Icon {
            content: url('../#{$icons-path}close-red-icon.svg');
            width: 8px;
            height: 8px;
            margin: 2px auto;
            display: block;
            inset-block-start: 1px;

            @include desktop {
                margin: 3px;
                inset-block-start: 0;
            }

            &_inStock {
                content: url('../#{$icons-path}check-green-icon.svg');
            }

            &Wrapper {
                margin-inline-end: .5rem;
                border: 1px solid var(--color-red);
                border-radius: 50%;
                height: 16px;
                width: 16px;
                display: inline-block;
                vertical-align: middle;

                @include desktop {
                    margin-inline-end: .8rem;
                }

                + span {
                    inset-block-start: 2px;

                    @include desktop {
                        inset-block-start: 1px;
                    }
                }

                &_inStock {
                    border-color: var(--color-green);
                }
            }
        }
    }

    &-StockStatusLabel {
        margin-inline-start: 5px;
        margin-inline-end: 5px;
        inset-block-start: -3px;

        @include after-mobile {
            inset-block-start: 1px;
        }
    }

    &-SizeChart {
        display: flex;
        max-width: 100vw;
        justify-content: center;
        border-top: 1px solid var(--color-input-border);
        border-bottom: 1px solid var(--color-input-border);
        margin: 20px 0 10px;

        @include desktop {
            font-family: var(--font-FFDIN);
            font-size: 12px;
            border-bottom: none;
            border-top: 0;
            display: flex;
            align-items: flex-start;
            width: fit-content;
            margin: 1.3rem 0;

            @include isArabic {
                font-family: var(--font-DINArabic);
            }

            &_isLoading {
                border-bottom: 0;
            }
        }

        button {
            text-decoration: none;
            font-weight: bold;
            height: 13px;
            font-size: 12px;
            will-change: color;
            transition: color .3s;
            color: var(--color-black);

            @include mobile {
                margin-block-start: 1.3rem;
                margin-block-end: 1.3rem;
            }
        }

        .Icon {
            content: url('../#{$icons-path}ruler.svg');
            inset-block-end: 5px;
            height: 24px;
            width: 24px;

            @include mobile {
                inset-block-end: 7px;
                margin-block-start: 1.3rem;
            }
        }

        &_isVariantsOutOfStock {
            @include desktop {
                inset-block-start: 255px;
            }
        }

        .TextPlaceholder {
            margin: 1.3rem 0;

            @include desktop {
                margin: 0;
            }
        }
    }

    &-QtyAndSizeWrapper {
        display: flex;
        flex-direction: column;

        &_isLoading {
            max-height: unset;
        }

        .ProductActions-Attributes {
            padding: 0;

            .ExpandableContent {
                margin: 0;
            }
        }

        .FieldSelect {
            height: 47px;

            @include after-mobile {
                height: 51px;
            }

            &-Select {
                padding-block-end: 5px;
                height: 45px;
                text-transform: capitalize;

                @include desktop {
                    height: 49px;
                    padding-block-start: 14px;
                    line-height: 18px;
                }
            }

            &-InnerLabel {
                position: absolute;
                z-index: 10;
                inset-inline-start: 16px;
                inset-block-start: 2px;
                text-transform: uppercase;
                font-size: var(--font-size-small);
                color: var(--color-input-label);

                @include desktop {
                    inset-inline-start: 20px;
                    font-size: var(--font-size-nano);
                }
            }

            &[aria-expanded='true'] {
                &::after {
                    transform: translateY(-120%) rotate(45deg);

                    @include desktop {
                        transform: translateY(-70%) rotate(225deg);
                    }
                }
            }

            &::after {
                @include mobile {
                    width: 7px;
                    height: 7px;
                    inset-block-start: 15px;
                }
            }
        }

        .ExpandableContent {
            width: 100%;

            &-Content {
                margin-block-end: 0;
            }

            &-Button {
                display: none;
            }
        }
    }

    &-QtyAndStockWrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;

        >.Field_type_select {
            flex-grow: 2.5;
            margin-block-end: 0;

            @include isArabic {
                flex-grow: 3;
            }

            .ProductActions-Qty {
                margin-block-end: 0;
            }
        }
    }

    &-Section {
        &_type_sku {
            order: unset;
            height: fit-content;
            flex-basis: unset;

            @include isArabic {
                text-align: end;
            }

            .TextPlaceholder {
                &::after {
                    display: block;
                }
            }

            @include mobile {
                border: none;
                padding-inline-end: 0;
                text-align: end;
            }
        }

        &_type_customizable_options {
            order: unset;
        }

        &_type_name {
            padding-block-start: 1.2rem;
            padding-inline-start: 0;
            padding-inline-end: 0;

            @include desktop {
                order: unset;
                font-family: var(--font-FFDIN);
                color: var(--color-black);
                margin-block-end: 2.2rem;
                padding-inline-start: 0;

                @include isArabic {
                    font-family: var(--font-DINArabic);
                }
            }
        }
    }

    &-quantityAndSizePlaceholder {
        @include desktop {
            margin-block-start: 40px;
        }

        &::after {
            display: block;
            height: 35px;

            @include desktop {
                height: 50px;
            }
        }
    }

    &-AddToWishlist {
        width: 15%;

        @include mobile {
            margin-block-start: 0;
            height: var(--add-to-cart-btn-height);
            padding: 0;
            margin-inline-start: 5px;
        }
    }

    &-PromoExcludedNotice {
        font-size: var(--font-size-medium-small);
        font-family: var(--font-FFDIN);
        padding: 1.5rem 0;
        border-top: 1px solid var(--color-input-border);
        border-bottom: 1px solid var(--color-input-border);

        @include isArabic {
            font-family: var(--font-DINArabic);
        }
    }

    &-ModelInformation {
        font-size: var(--font-size-medium-small);
        font-family: var(--font-FFDIN);
        padding: 12px;
        border: 1px solid var(--color-input-border);
        border-radius: 4px;
        margin-block-start: 8px;

        @include isArabic {
            font-family: var(--font-DINArabic);
        }

        @include mobile {
            margin-block-end: 8px;
        }
    }
}
