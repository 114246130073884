/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-measurements-header-font-size: 25px;
}

.ProductMeasurements {
    text-align: center;
    padding: 48px 0 30px 0;

    &-Wrapper {
        max-width: 420px;

        @include desktop {
            max-width: 600px;
        }

        @include small-desktop {
            max-width: var(--content-wrapper-width);
        }
    }

    &-Header {
        h2 {
            font-size: var(--font-size-medium);
            margin: 25px 0 13px 0;

            @include desktop {
                font-size: var(--product-measurements-header-font-size);
                margin: 40px 0 25px 0;
            }
        }

        p {
            text-align: center;
            margin-block-start: 6px;

            @include mobile {
                text-align: start;
            }
        }
    }

    &-Buttons {
        text-align: end;
        margin: 20px 0 5px 0;
    }

    &-Button {
        background-color: var(--secondary-base-color);
        font-weight: bold;
        font-size: var(--font-size-normal-small);
        text-transform: uppercase;
        padding: 2px 15px;

        @include desktop {
            font-size: var(--font-size-medium-small);
        }

        &_isActive {
            background-color: var(--color-black);
            color: var(--color-white);
        }

        &:first-of-type {
            margin-inline-end: 5px;

            @include isArabic {
                margin-inline-end: 0;
                margin-inline-start: 5px;
            }
        }
    }

    &-Tables {
        width: fit-content;
        margin: 0 auto;
    }


    &-Table {
        display: none;

        &_isVisible {
            display: block;
        }

        .Table {
            &-Wrapper {
                overflow-x: unset;
                border-top: 1px solid var(--secondary-base-color);

                table {
                    margin-block-end: 25px;
                    outline: 1px solid var(--secondary-base-color);

                    tr {
                        &:last-child {
                            td {
                                border-bottom: 0;
                            }
                        }
                    }
                }

                th {
                    background-color: var(--color-white);
                    font-weight: 700;
                    border: {
                        inset-block-end: 1px solid var(--secondary-base-color);
                        inset-inline-end: 1px solid var(--secondary-base-color);
                    }

                    @include isArabic {
                        border: {
                            inset-inline-end: 0;
                            inset-inline-start: 1px solid var(--secondary-base-color);
                        }
                    }
                }

                td {
                    background-color: var(--secondary-base-color);
                    border: 1px solid var(--color-white);

                    &:first-child {
                        font-weight: 700;
                        background-color: var(--color-white);
                        border: 1px solid var(--secondary-base-color);
                    }
                }

                th,
                td {
                    cursor: pointer;
                    text-align: center;
                    font-size: var(--font-size-nano);
                    min-width: unset;
                    padding: 3px 2px;
                    width: 105px;
                    height: 19px;

                    @include desktop {
                        font-size: var(--font-size-normal);
                        padding: 10px;
                        width: 10rem;
                        height: 38px;
                    }

                    &:first-child {
                        border-left: 1px solid var(--secondary-base-color);

                        @include isArabic {
                            border-left: 1px solid var(--secondary-base-color);
                        }
                    }

                    &:last-child {
                        @include isArabic {
                            border-right: 1px solid var(--secondary-base-color);
                        }
                    }

                    &.ProductMeasurements-Cell {
                        &_intermediate {
                            background-color: var(--color-grey-alto);
                        }

                        &_edge, &_point {
                            background-color: var(--color-black);
                            color: var(--color-white);
                        }
                    }
                }
            }
        }
    }
}
