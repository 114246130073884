/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductCard {
    padding: 25px 0;
    margin-block-end: 0;

    @include desktop {
        max-width: 240px;
        margin: 20px auto;
        padding: 5px 5px 20px;
    }

    &:last-child {
        margin-block-end: 20px;
    }

    @include after-mobile {
        &:hover {
            outline: 1px solid var(--color-grey-c);
        }
    }

    a:hover {
        text-decoration: none;
    }

    &-ContentPlaceholder {
        padding-block-end: 150%;
    }

    &-Content {
        text-align: center;
        padding: 5px 0 0;

        .TextPlaceholder {
            margin: 0 auto;

            &_length_long::after {
                @include mobile {
                    content: '***************************************';
                }
            }
        }

        &_isThumbnail {
            button.ProductAttributeValue { // Increase selector importance
                margin: 0 auto;
                height: 54px;
                width: 54px;

                @include mobile {
                    height: 44px;
                    width: 44px;
                }
            }
        }
    }

    &-Price {
        width: 100%;

        @include mobile {
            margin-block-start: 5px;
        }
    }

    &-PriceWrapper {
        margin: 0 auto;

        @include desktop {
            margin: 0 auto 38px;
        }
    }

    &-ListItem {
        margin-block-end: 0;
        padding-inline-start: 0;
        min-width: 32px;
        display: block;

        &:last-child {
            margin-block-end: 0;
        }

        &::before {
            content: none;
        }

        .TextPlaceholder {
            font-size: 20px;
        }
    }

    &_isLoading {
        display: flex;
        justify-content: center;
        margin: auto;
        align-items: center;
        z-index: 2;
        width: min-content;
        padding: 0;

        &:hover {
            outline: unset;
        }
    }

    &-Name {
        font-size: var(--font-size-medium-small);
        font-weight: 700;
        margin: 0;
        min-height: 2.5rem;

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        @include desktop {
            font-size: var(--font-size-medium);
            font-weight: initial;
            margin: .8rem 0 .7rem;
            min-height: 3.5rem;
        }

        &_isPlaceholder {
            font-size: unset;
            padding: 5px 0;
            min-height: 3rem;

            @include desktop {
                padding: 0;
            }
        }

        + .TextPlaceholder {
            overflow: hidden;
        }
    }

    &-Picture {
        border-bottom: solid 1px var(--color-grey-c);
        padding-block-end: 100%;

        &_isPlaceholder {
            border-bottom: unset;
            padding-block-end: 0;

            &::after {
                content: 'aa';
                display: block;
                height: 220px;
                width: 100%;
                color: transparent;
                background-image: var(--placeholder-image);
                background-size: var(--placeholder-size);
                animation: var(--placeholder-animation);

                @include mobile {
                    height: 162px;
                }
            }
        }
    }

    &-ConfigurableOptions {
        justify-content: center;
        height: 3.5rem;
    }

    &-ProductButton {
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        font-weight: bold;
        font-size: var(--font-size-medium);
        position: absolute;
        inset-block-start: 40%;
        text-align: center;
        inset-inline-start: 50%;
        z-index: 1;
        padding: 12px 14px;
        text-transform: uppercase;
        transform: translateX(-50%);
        transition: visibility 0s, opacity .2s linear, color .2s linear, background-color .2s linear;

        &_isVisible {
            visibility: visible;
            opacity: 1;
        }
    }

    &-NumberOfColorSwatches {
        visibility: visible;
        width: 50%;
        inset-inline-start: 0; 
        inset-inline-end: 0; 
        margin-inline-start: auto; 
        margin-inline-end: auto; 
        color: var(--product-card-number-of-color-swatches);
        font-size: var(--font-size-micro);
        line-height: 1.333;
        text-transform: uppercase; 
        position: absolute;
        inset-block-start: 15%;

        @include desktop {
            font-size: var(--font-size-normal-small);
        }
    }

    &-Swatches {
        margin: auto;
        width: 100%;

        &_isVisible {
            opacity: 0;
        }

        .SwatchSlider {
            &-Arrow {
                &_btnType_left {
                    @include isArabic {
                        inset-inline-end: unset;
                    }
                }
            }
        }
    }

    .ProductAttributeValue {
        height: 35px;
        width: 35px;

        @include mobile {
            height: 22px;
            width: 22px;
        }

        &-ColorLink {
            &_isActive,
            &:hover {
                border: 1px solid var(--color-grey-c);
                outline: none;
            }
        }
    }

    &-AddToCart {
        inset-block-end: -40px;
        margin: 0 auto;
        inset-inline-start: 0;
        position: absolute;
        inset-inline-end: 0;
        z-index: 1;

        @include after-mobile {
            inset-block-end: -8px;
        }

        @include desktop {
            display: none;
        }

        .Button-AddToCart {
            padding: 8px 10px;
            transform: scale(0.8);
        }
    }

    &:hover {
        .ProductCard {
            &-AddToCart {
                display: block;
            }
        }
    }
}
