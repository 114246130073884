/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductGalleryThumbnailImage {
    @include after-mobile {
        display: block;
        height: 98px;
        width: 98px;
    }

    &:hover::after {
        border-bottom: solid 2px var(--color-black);
    }

    &::after {
        content: '';
        border-bottom: solid 1px var(--color-gray-gainsboro);
        position: absolute;
        inset-block-end: 1px;
        inset-inline-start: 1rem;
        inset-inline-end: 1rem;
        height: 0;
    }

    &_isActiveImage {
        &::after {
            border-bottom: solid 2px var(--color-red);
        }
    }

    &_isLoading {
        &::after {
            content: none;
        }

        .Image {
            &:hover {
                &::after {
                    border-bottom: 0;
                }
            }
        }
    }

    img {
        padding: 11px;
        background-color: var(--color-white);
    }
}
