/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.product-hotspot-hero {
    position: relative;

    .Image {
        padding-block-end: 0;

        &-Image_isLoading {
            min-height: 300px;

            @include desktop {
                min-height: 400px;
            }
        }
    }

    img {
        object-fit: cover;
        position: relative;
    }

    .ProductCarousel {
        img {
            position: absolute;
        }

        .Image {
            padding-block-end: 100%;
        }
    }
}

.product-hotspot-hero__buttons {
    display: flex;
    margin-block-start: 2rem
}

@media only screen and (min-width: 1025px) {
    .product-hotspot-hero__buttons {
        flex-direction: row;
        margin-inline-start: -1rem;
        margin-inline-end: -1rem
    }
}

@media (max-width: 1024px) {
    .product-hotspot-hero__buttons {
        justify-content: center;
        margin-block-start: 1.6rem
    }
}

@media only screen and (min-width: 1025px) {
    .product-hotspot-hero__button-w {
        padding: 0 1rem
    }
}

.product-hotspot-hero__button {
    pointer-events: auto;
    border: .1rem solid #000
}

@media (max-width: 1024px) {
    .product-hotspot-hero__button {
        border: .1rem solid #000;
        margin-inline-start: .64rem;
        margin-inline-end: .64rem
    }
}

@media (max-width: 1024px) {
    .product-hotspot-hero__button {
        margin-block-end: 1.6rem
    }
}

@media (max-width: 1024px) {
    .product-hotspot-hero__media {
        position: relative
    }
}

.product-hotspot-hero__image {
    width: 100%;
    height: auto;
    display: block
}

.product-hotspot-hero__hotspot {
    display: none;
    width: 3rem;
    height: 3rem;
    position: absolute;
    border-radius: 50%;
    border: .3rem solid #222;
    box-shadow: 0 0 1.5rem -.1rem #fff;
    transition: all .3s ease
}

@media only screen and (min-width: 1025px) {
    .product-hotspot-hero__hotspot:hover {
        border: .3rem solid red;
        box-shadow: none
    }

    .product-hotspot-hero__hotspot:hover::before {
        content: '';
        position: absolute;
        inset-block-start: -.6rem;
        inset-inline-start: -.6rem;
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 50%;
        border: .6rem solid red
    }

    .product-hotspot-hero__hotspot:hover::after {
        background: radial-gradient(circle closest-side, #000, #000 .2rem, #fff .2rem, #fff 1.1rem, #888 .2rem, #888)
    }
}

.product-hotspot-hero__hotspot::after {
    content: '';
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background: radial-gradient(circle closest-side, red, red .2rem, #fff .2rem, #fff .7rem, rgba(255, 255, 255, .25) .8rem, rgba(255, 255, 255, .25))
}

.product-hotspot-hero__content-w {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    pointer-events: none
}

@media only screen and (min-width: 1025px) {
    .product-hotspot-hero__content-w {
        position: absolute;
        inset-block-start: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        padding: 3.2rem 9.6rem
    }
}

@media (max-width: 1024px) {
    .product-hotspot-hero__content-w {
        text-align: center
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product-hotspot-hero__content-w {
        padding: 3.5rem 5rem
    }
}

@media (max-width: 767px) {
    .product-hotspot-hero__content-w {
        padding: 2.4rem
    }
}

.product-hotspot-hero__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 55px 'FFDIN', Arial, sans-serif;
    font: 5.5rem 'FFDIN', Arial, sans-serif;
    line-height: 1.1;
    margin-block-end: 1rem;
    text-transform: uppercase;
    color: #222
}

@media (max-width: 1024px) {
    .product-hotspot-hero__title {
        margin-block-end: 1.4rem
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product-hotspot-hero__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 27px 'FFDIN', Arial, sans-serif;
        font: 2.7rem 'FFDIN', Arial, sans-serif
    }
}

@media (max-width: 767px) {
    .product-hotspot-hero__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 22px 'FFDIN', Arial, sans-serif;
        font: 2.24rem 'FFDIN', Arial, sans-serif;
        line-height: 1.1
    }
}

.product-hotspot-hero__text {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 20px 'FFDIN', Arial, sans-serif;
    font: 2rem 'FFDIN', Arial, sans-serif;
    text-transform: capitalize;
    color: #222
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product-hotspot-hero__text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN', Arial, sans-serif;
        font: 1.8rem 'FFDIN', Arial, sans-serif
    }
}

@media (max-width: 767px) {
    .product-hotspot-hero__text {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 18px 'FFDIN', Arial, sans-serif;
        font: 1.76rem 'FFDIN', Arial, sans-serif;
        color: #444
    }
}
