/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --space-between-slides: 10px;
    --product-carousel-title-font-size: 22px;

    @include desktop {
        --product-carousel-title-font-size: 27px;
    }

    @include small-desktop {
        --product-carousel-title-font-size: 29px;
    }
}

.ProductCarousel {
    order: 4;
    margin: 0 auto;
    min-height: 0;
    transition: min-height .3s ease-in;

    @include desktop {
        transition: min-height .7s ease-in;
    }

    &_isFeatureHero {
        inset-block-start: 100px;
    }

    &_isLoading {
        overflow: hidden;
        min-height: 350px;

        @include desktop {
            min-height: 450px;
        }
    }

    &-Wrapper {
        padding: 0 40px;

        .SwatchSlider {
            justify-content: unset;
        }

        @include desktop {
            padding: 0 70px;
        }

        @include small-desktop {
            padding: 0 90px;
        }
    }

    &-SliderWrapper {
        max-width: 1020px;

        .SwatchSlider-Wrapper {
            display: none;
        }
    }

    &-ProductCard {
        display: flex;
        justify-content: center;
        min-width: var(--slide-width);

        .ProductCard {
            padding: 20px 0;
            width: calc(var(--slide-width) - var(--space-between-slides));

            &:hover {
                outline: 0;
            }

            &-Name {
                font-size: var(--font-size-medium-small);
                margin: 0 0 5px 0;
                min-height: 3.2rem;

                @include small-desktop {
                    font-size: var(--font-size-medium);
                }
            }

            &-Price {
                display: flex;
                flex-direction: column;

                @include desktop {
                    display: inline-block;
                }

                .ProductPrice {
                    &-CurrentPrice {
                        font-size: var(--font-size-medium-big);
                        margin: 0 auto;
                    }

                    &-HighPrice {
                        font-size: var(--font-size-normal-small);
                        margin: 0 auto;
                    }
                }
            }

            .Slider-ArrowContainer {
                @include isArabic {
                    &:not(:first-child) {
                        inset-inline-start: unset;
                    }
                }
            }
        }
    }

    & &-Title {
        font-size: var(--product-carousel-title-font-size);
        margin: 0;
        padding: 24px 0 0;
        text-align: center;
        word-break: break-all;
    }

    &-Arrow {
        inset-block-start: calc(50% - 11px);
        height: 22px;
        width: 22px;
        transition: .3s;
        border: {
            inset-inline-start: 1px solid var(--color-grey-alto);
            inset-block-start: 1px solid var(--color-grey-alto);
        }

        @include small-desktop {
            inset-block-start: calc(50% - 18px);
            height: 35px;
            width: 35px;
            border: {
                inset-inline-start: 2px solid var(--color-grey-alto);
                inset-block-start: 2px solid var(--color-grey-alto);
            }
        }

        &_isDesktop {
            &:hover {
                border-color: var(--color-black);
            }
        }

        &_btnType {
            &_left {
                inset-inline-start: -25px;

                @include desktop {
                    inset-inline-start: -45px;
                }

                @include small-desktop {
                    inset-inline-start: -60px;
                }

                @include isArabic {
                    inset-inline-end: unset;
                }
            }

            &_right {
                inset-inline-end: -25px;

                @include desktop {
                    inset-inline-end: -45px;
                }

                @include small-desktop {
                    inset-inline-end: -60px;
                }
            }
        }

        &_hasNextImage {
            border-color: var(--color-gray-crumb);
        }
    }

    .ProductLabel {
        display: none;
    }
}
