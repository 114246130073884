/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

.ProductInformation {
    border: none;

    &-Wrapper {
        display: flex;
        justify-content: center;

        @include mobile {
            padding-inline: 2.3rem;
            padding-inline-end: 2.3rem;
        }

        @include after-mobile {
            margin: 0 auto;
        }

        div {
            max-width: 420px;

            @include desktop {
                max-width: 1100px;
                margin-inline: 10px;
            }
        }

        h2 {
            display: flex;
            justify-content: center;

            .TextPlaceholder {
                display: flex;

                &::after {
                    display: block;
                    width: 240px;

                    @include desktop {
                        width: 250px;
                    }
                }
            }
        }

        h3 {
            font-size: 1.5rem;
            line-height: 36px;
            font-weight: 700;

            @include mobile {
                font-size: 1.2rem;
            }
        }

        p {
            line-height: 2.1;
            font-size: var(--font-size-medium);

            @include mobile {
                font-size: var(--font-size-normal-small);
            }
        }

        ul {
            margin-inline-start: 3.7rem;

            @include mobile {
                margin-inline-start: 0;
            }

            li {
                font-size: var(--font-size-medium);

                @include mobile {
                    padding-inline-start: 1.2rem;
                    font-size: var(--font-size-normal-small);
                }

                &::before {
                    inset-inline-start: 0;
                    font-size: var(--font-size-micro);
                    margin-inline-start: unset;
                    margin-inline-end: unset;
                    margin-block-start: .3rem;

                    @include mobile {
                        margin-block-start: 0;
                    }
                }
            }
        }

        li {
            font-size: var(--font-size-medium);

            @include mobile {
                padding-inline-start: 1.2rem;
                padding-inline-end: 1.2em;
                font-size: var(--font-size-normal-small);
            }

            &::before {
                font-size: var(--font-size-micro);
                margin-block-start: .3rem;
                position: relative;

                @include mobile {
                    margin-block-start: 0;
                    margin-inline-end: 1.2em;
                    position: absolute;
                    inset-inline-end: -25px;
                }
            }
        }

        .ExpandableContent {
            border: none;
        }
    }

    .ExpandableContent-Button {
        text-align: center;
        pointer-events: none;
        font-size: 2.5rem;
        letter-spacing: .02em;

        @include mobile {
            text-align: center;
        }

        .ExpandableContent-Heading {
            @include mobile {
                margin: 0 auto;
                padding-block-start: 25px;
            }
        }

        &::before,
        &::after {
            display: none;
        }
    }

    &-ContentPlaceholder {
        &::after {
            display: block;
            width: 250px;
            height: 300px;

            @include desktop {
                width: 700px;
                height: 500px;
            }
        }
    }

    &-Description {
        line-height: 2.1;
        font-size: var(--font-size-medium);

        @include mobile {
            font-size: var(--font-size-normal-small);
        }

        @include desktop {
            ul {
                margin-inline-start: 0;

                li {
                    padding-inline-start: 0;
                }
            }

            .FirstBlock {
                display: flex;
                flex-wrap: wrap;
                margin-inline: 0;

                h3:nth-of-type(1) {
                    order: 1;
                }

                h3:nth-of-type(2) {
                    order: 2;
                }

                ul:nth-of-type(1) {
                    order: 3;
                    padding-inline-end: 45px;
                }

                ul:nth-of-type(2) {
                    order: 4;
                }

                h3, ul {
                    width: 50%;
                }
            }
        }
    }
}
