/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.product-carousel {
    margin-inline-start: 5rem;
    margin-inline-end: 5rem
}

@media (max-width: 1180px) {
    .product-carousel {
        margin-inline-end: 0;
        margin-inline-start: 0
    }
}

@media only screen and (min-width: 1025px) {
    .product-carousel .kop-carousel {
        opacity: 0
    }
}

@media only screen and (min-width: 1025px) {
    .product-carousel .kop-carousel.slick-initialized {
        opacity: 1;
        transition: all .5s ease-out
    }
}

.product-carousel__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 30px 'FFDIN', Arial, sans-serif;
    font: 3rem 'FFDIN', Arial, sans-serif;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .09rem;
    color: #222;
    margin-block-start: 2.5rem;
    margin-block-end: 2.5rem
}

@media (max-width: 1024px) {
    .product-carousel__title {
        margin-block-start: 2.1rem;
        margin-block-end: 2.4rem
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product-carousel__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 27px 'FFDIN', Arial, sans-serif;
        font: 2.7rem 'FFDIN', Arial, sans-serif;
        letter-spacing: .06rem
    }
}

@media (max-width: 767px) {
    .product-carousel__title {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 22px 'FFDIN', Arial, sans-serif;
        font: 2.24rem 'FFDIN', Arial, sans-serif;
        letter-spacing: .048rem
    }
}

.product-carousel__title ~ .product-carousel__products {
    margin-block-start: 0
}

.product-carousel__products {
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    margin: 3.5rem auto 6rem;
    padding-inline-start: 5.2rem;
    padding-inline-end: 5.2rem;
    max-width: 118rem
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product-carousel__products {
        margin-block-start: 1.5rem;
        margin-block-end: 4rem
    }
}

@media (max-width: 767px) {
    .product-carousel__products {
        margin-block-start: 2.5rem;
        margin-block-end: 2rem;
        padding-inline-start: 2rem;
        padding-inline-end: 2rem
    }
}

.product-carousel__item {
    margin-inline-start: .95rem;
    margin-inline-end: .95rem
}

@media (max-width: 767px) {
    .product-carousel__item {
        margin-inline-start: .8rem;
        margin-inline-end: .8rem
    }
}

.product-carousel__link {
    display: block;
    cursor: pointer
}

@media only screen and (min-width: 1025px) {
    .product-carousel__link:hover .product-carousel__button {
        display: block
    }
}

.product-carousel__img-wrapper {
    position: relative;
    padding-block-end: 2rem;
    margin-block-end: 1.7rem;
    border-bottom: .1rem solid #e1e1e1
}

@media (max-width: 767px) {
    .product-carousel__img-wrapper {
        padding-block-end: 1rem;
        margin-block-end: 1rem
    }
}

.product-carousel__img {
    margin: 0 auto
}

.product-carousel__button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 15px 'FFDIN', Arial, sans-serif;
    font: 1.5rem 'FFDIN', Arial, sans-serif;
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: none;
    padding: .8rem 1.2rem;
    text-transform: uppercase
}

.product-carousel__name {
    font: 15px 'FFDIN', Arial, sans-serif;
    font: 1.5rem 'FFDIN', Arial, sans-serif;
    text-align: center;
    color: #222;
    margin-block-end: .5rem;
    height: 2.3rem
}

@media (max-width: 767px) {
    .product-carousel__name {
        font: 14px 'FFDIN', Arial, sans-serif;
        font: 1.4rem 'FFDIN', Arial, sans-serif;
        margin-block-end: .5rem
    }
}

.product-carousel__price {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 15px 'FFDIN', Arial, sans-serif;
    font: 1.5rem 'FFDIN', Arial, sans-serif;
    text-align: center;
    color: #222
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product-carousel__price {
        font: 17px 'FFDIN', Arial, sans-serif;
        font: 1.7rem 'FFDIN', Arial, sans-serif
    }
}

@media (max-width: 767px) {
    .product-carousel__price {
        font: 16px 'FFDIN', Arial, sans-serif;
        font: 1.6rem 'FFDIN', Arial, sans-serif
    }
}

.product-carousel__price .price {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: 15px 'FFDIN', Arial, sans-serif;
    font: 1.5rem 'FFDIN', Arial, sans-serif
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product-carousel__price .price {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 17px 'FFDIN', Arial, sans-serif;
        font: 1.7rem 'FFDIN', Arial, sans-serif
    }
}

@media (max-width: 767px) {
    .product-carousel__price .price {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font: 16px 'FFDIN', Arial, sans-serif;
        font: 1.6rem 'FFDIN', Arial, sans-serif
    }
}

.product-carousel__price .old-price .price {
    font: 12px 'FFDIN', Arial, sans-serif;
    font: 1.2rem 'FFDIN', Arial, sans-serif
}

.product-carousel .slick-arrow {
    inset-block-start: calc(50% - 8.7rem / 2)
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .product-carousel .slick-arrow {
        inset-block-start: calc(50% - 8.85rem / 2)
    }
}

@media (max-width: 767px) {
    .product-carousel .slick-arrow {
        inset-block-start: calc(50% - 7.15rem / 2)
    }
}
