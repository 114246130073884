/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CategoryProductList {
    @include after-mobile {
        inset-block-start: -56px;
        grid-column: 2;
        padding-block-end: 0;
        min-height: 100vh;
    }

    @include desktop {
        inset-block-start: unset;
    }

    &-Page {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: max-content;
        grid-column-gap: 1rem;

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mobile {
            grid-template-columns: 1fr 1fr;
            border: 0;
            padding: 0;
        }

        @include after-mobile {
            grid-column: 2;
            margin-block-start: 1rem;
        }

        @include desktop {
            margin-block-start: 0;
        }

        // remove margin from top to other pages

        ~ .CategoryProductList-Page {
            @include after-mobile {
                margin-block-start: 0;
            }
        }

        >div>li {
            padding-inline-start: 0;

            @include desktop {
                padding-inline-start: 5px;
            }

            @include mobile {
                >div {
                    &:first-child {
                        max-width: 176px;
                    }
                }
            }
        }
    }

    &-ProductsMissing {
        padding: 1rem;
        text-align: center;
    }

    &-More {
        min-height: 3rem;
    }

    &-LoadButton {
        background-color: var(--category-product-list-load-button-background);
        cursor: pointer;
        font-size: 13pt;
        margin-block-end: 1rem;
        padding: 1em 0;
        text-align: center;
    }
}
