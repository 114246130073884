/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

.ProductAttributeValue {
    display: block;
    height: 29px;
    width: 29px;

    @include mobile {
        height: 36px;
        width: 36px;
    }

    .ProductConfigurableAttributes-SwatchList_isThumbnail & {
        height: 64px;
        width: 64px;

        @include mobile {
            height: auto;
            max-width: 60px;
            width: calc(100% / 5 - 6px);
        }
    }

    &-ColorLink {
        height: 100%;
        padding: .2rem;
        width: 100%;
        margin: 0;

        @include desktop {
            margin: auto;
            padding: .4rem;
        }

        img {
            // Targeting iOS devices, since -webkit-touch-callout is only for Safari mobile
            // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-touch-callout
            @supports (-webkit-touch-callout: none) {
                inset-block-start: -1px;

                @include desktop {
                    inset-block-start: 0;
                }
            }
        }

        .ProductActions & {
            height: 100%;
            padding: 1px;
            width: 100%;
        }

        &_isActive,
        &:hover {
            outline: 1px solid var(--color-grey-c);
        }

        .Image {
            display: block;
        }
    }

    &-Color,
    &-Image-Overlay {
        border-radius: 0;
        margin: 0 3px 14px 3px;
        box-shadow: none;
        display: flex;
        min-height: auto;
        width: 30px;
        height: 30px;

        @include mobile {
            width: 39px;
            height: 39px;
            margin: 0 4px 12px 3px;
        }

        &::before,
        &::after {
            box-shadow: none;
            border: none;
        }

        &::before {
            display: none;
        }

        &::after {
            transform: none;
            border-bottom: solid 3px $c-red;
            width: 30px;
            opacity: var(--option-is-selected);
            inset-inline-start: 0;
            height: 34px;
            inset-block-start: 0;
            transition: none;

            @include mobile {
                width: 39px;
                height: 0;
                inset-block-start: 47px;
            }
        }

        &:hover {
            @include after-mobile {
                &::after {
                    opacity: 1;
                }
            }
        }
    }

    &-String {
        margin: 0;
        width: 43px;
        height: 43px;
        overflow: hidden;
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;
        color: $black;
        font-size: 12px;
        border-color: $c-input-border;

        @include mobile {
            height: 54px;
            width: calc(20vw - 16px);
        }

        &::after {
            content: '';
            transform: none;
            border-bottom: solid 3px $c-red;
            opacity: var(--option-is-selected);
            transition: none;
            position: absolute;
            inset-inline-start: 0;
            width: 45px;
            inset-block-start: 38px;
            height: 0;

            @include mobile {
                width: 100%;
                inset-block-start: 48px;
            }
        }

        &:hover {
            @include after-mobile {
                background-color: $c-gray-e;
                font-weight: bold;

                &::after {
                    opacity: 1;
                }
            }
        }

        &_isSelected {
            background-color: $c-gray-e;
            font-weight: bold;

            &::after {
                opacity: 1;
            }
        }
    }

    &-Text {
        padding: 0;
        min-height: auto;

        label:first-of-type {
            padding: 0;
        }

        &_isSelected {
            .ProductAttributeValue-Label {
                color: $c-red;
            }
        }

        .Field {
            label {
                display: none;
            }
        }
    }

    &-Label {
        padding-inline-start: 0;
        font-size: 14px;
        text-transform: none;
        color: $black;

        &:hover {
            color: $c-red;
        }
    }

    &_isNotAvailable {
        display: none;
    }

    &-Color {
        &_showBorder {
            border: 1px solid var(--color-grey-e);
        }
    }
}
