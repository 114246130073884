/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductConfigurableAttributes {
    &-Placeholder {
        margin-block-end: 0;
        margin-inline-start: 0;
        height: 100%;
        width: 100%;
    }

    &-ColorText {
        font-weight: bold;
        width: 100%;
    }

    &-ColorLabelPlaceholder {
        &::after {
            display: inline-block;
            width: 32px;
            margin-inline-end: 4px;
        }
    }

    &-ColorTextPlaceholder {
        &::after {
            display: inline-block;
            width: 70px;
        }
    }
}
