/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

.ProductPage {
    padding-block-end: 0;
    margin-block-end: 10px;

    @include isArabic {
        * {
            font-family: var(--font-FFDIN); // Removing --DINArabic font for PDP, as requested
        }
    }

    @include desktop {
        padding-block-start: 33px;
    }

    @include mobile {
        margin: 0 0 10px 0;
    }

    > section {
        &:nth-child(even) {
            background: var(--color-white-smoke);
        }
    }

    &-ContentBlockPlaceholder {
        @include placeholder-animation();

        min-height: 475px;
        height: 100%;

        @include desktop {
            min-height: 300px;
        }
    }

    &-Section {
        @include desktop {
            display: flex;
            justify-content: center;
        }
    }

    &-Wrapper {
        margin: 0;
        padding-inline-start: 23px;
        padding-inline-end: 23px;

        @include desktop {
            padding-inline-start: 100px;
            padding-inline-end: 100px;
            grid-template-columns: minmax(0, 86rem) 36rem;
            max-width: none;
        }

        &::before,
        &::after {
            display: none;
            inset-block-end: 40px;
            inset-inline-start: 0;

            @include desktop {
                display: initial;
                content: '';
                height: 0;
                width: 100%;
                border-top: 1px solid var(--color-input-border);
                position: absolute;
            }
        }

        &::after {
            inset-inline-start: 0;
            inset-block-end: 1px;
        }
    }

    &-OrganizationSchema {
        display: none;
    }

    .ProductActions-Title {
        @include isArabic {
            font-family: var(--font-DINArabic);
        }
    }
}
