/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/* stylelint-disable declaration-no-important */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductGallery {
    @include desktop {
        max-height: 60rem;
    }

    .PdpDesktopImages {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: none;

        >div {
            height: var(--image-height);
        }
    }

    &-SliderOverlay_isVisible {
        --slider-height: 100%;
        --slider-overlay-top: 0;

        height: 100vh;
        width: 100vw;
        z-index: 200;
        background-color: var(--color-white);
        position: fixed;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-start: var(--slider-overlay-top);

        @include desktop {
            --slider-overlay-height: 93vh;
        }

        @include large-desktop {
            inset-block-start: calc(var(--slider-overlay-top) + var(--header-height) - var(--navigation-tabs-height));
        }

        svg {
            height: 23px;
            width: 23px;
            inset-inline-end: 27px;
            inset-block-start: 23px;
            position: absolute;
            z-index: 30;
            cursor: pointer;
            transition: fill .2s ease-in-out;
            fill: var(--color-grey-c);

            @include isArabic {
                inset-inline-end: calc(100% - 47px);

                @include large-desktop {
                    inset-inline-end: calc(100% - 67px);
                    inset-block-start: 40px;
                }
            }

            &:hover {
                fill: var(--color-red);
                transition: fill .2s ease-in-out;
            }
        }

        .ProductGallery {
            &-SliderImage {
                cursor: grab;
                margin: 0 auto;

                &_isNotZoomedIn {
                    cursor: zoom-in;
                }

                &_isSliderOverlay {
                    height: var(--slider-overlay-height);
                }
            }

            &-Slider {
                height: var(--slider-overlay-height);
            }
        }
    }

    &-ImageButton {
        width: 100vw;
        margin: 0 auto;

        @include desktop {
            max-width: 70%;
        }
    }

    &-Additional {
        margin: 20px 0 5px;

        @include before-desktop {
            display: none;
        }

        &_isLoading {
            margin: 3px 0 0 0;
            overflow: unset;
        }

        .Draggable {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(61px, 1fr));
            inset-inline-start: auto;
        }
    }

    &-Slider {
        --slider-height: 100%;

        min-height: 40rem;
        height: var(--slider-height);

        @include mobile {
            --slider-height: 420px;

            min-height: 30rem;
        }

        &::after {
            @include desktop {
                content: '';
                box-shadow: inset 0 0 0 3px #fff;
                width: 100%;
                height: 100%;
                inset-block-start: 0;
                inset-inline-start: 0;
                position: absolute;
                pointer-events: none;
            }
        }
    }

    &-SliderImage {
        cursor: url(./images/cursor-zoom.png), auto;

        @include desktop {
            img {
                pointer-events: all !important;
            }
        }
    }

    .Slider-Crumbs {
        inset-block-end: 80px;

        @include desktop {
            inset-block-end: var(--breadcrumbs-bottom);
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;

        &_blank {
            opacity: 0;
        }
    }

    &-Notice {
        color: var(--secondary-dark-color);
        font-size: .9rem;
        text-align: center;

        @include mobile {
            display: none;
        }
    }

    .Slider-Arrow {
        @include mobile {
            display: none;
        }
    }

    &-Glide {
        width: 100%;
        height: 3px;
        display: flex;
        margin-block-end: 10px;
        background-color: var(--secondary-base-color);
    }

    &-GlideStage {
        flex: 1;
        height: 3px;

        &_isActive {
            background-color: var(--color-black);
        }
    }
}

.overscrollPrevented {
    overscroll-behavior: contain;
}

.overlayFullScreen {
    overflow-y: hidden;
}
