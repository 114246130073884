/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-number-of-color-swatches: #6c6c6c;
    --product-cart-picture-label-background: rgba(255, 255, 255, .7);
}

.ProductCard {
    padding-inline-start: 5px;
    min-width: 0;

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: .6rem;
    }

    &-Content {
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        padding-block-start: 23px;
    }

    &-Name,
    &-Brand {
        width: 100%;
        font-size: .9rem;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;

        &_isLoaded {
            text-overflow: ellipsis;

            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-height: 2.5rem;
            }
        }

        @include after-mobile {
            font-size: 14px;
            line-height: 1.3;
        }
    }

    &-Name {
        @include after-mobile {
            margin-block-start: .5rem;
        }
    }

    &-Brand {
        font-weight: 300;
        opacity: .5;
        min-height: 2.5rem;

        @include mobile {
            line-height: 1;
            font-size: 12px;
        }
    }

    &-Picture {
        padding-block-end: 120%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-Reviews {
        display: none;
    }

    &-ConfigurableOptions {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &-Color {
        --product-card-color-size: 9px;

        @include after-mobile {
            --product-card-color-size: 10px;
        }

        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-inline-start: calc(var(--product-card-color-size) / 3);
        border: 1px solid var(--product-card-color-border-color);
    }

    &-Price {
        @include after-mobile {
            font-size: 20px;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        margin-inline-end: 5px;
        font-size: 10px;
        position: absolute;
        inset-block-start: 7px;
    }

    &-Figure {
        flex-grow: 1;

        .Slider {
            &-Arrow {
                display: none;

                @include mobile {
                    display: block;

                    &_noNextSlide {
                        display: none;
                    }

                    &_type_right {
                        transform: rotate(-45deg);
                    }

                    &_type_left {
                        transform: rotate(135deg);
                    }
                }

                &Container {
                    --slider-color: var(--color-black);

                    display: none;
                    border-radius: 15px;
                    position: absolute;
                    inset-block-start: 45%;
                    inset-inline-start: 5%;
                    height: 30px;
                    width: 30px;
                    z-index: 1;

                    @include mobile {
                        display: block;

                        &_noNextSlide {
                            display: none;
                        }
                    }

                    &:not(:first-child) {
                        inset-inline-start: unset;
                        inset-inline-end: 5%;
                    }

                    &:hover {
                        --slider-color: var(--color-red);
                    }
                }

                &_type_right,
                &_type_left {
                    inset: 0;
                    margin: auto;
                    border: solid var(--slider-color);
                    border-width: 0 2px 2px 0;
                    height: 12px;
                    width: 12px;

                    @include isArabic {
                        inset: 0;
                    }
                }
            }
        }
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;

        &:hover {
            .ProductCard-Figure {
                .Slider {
                    &-Arrow {
                        display: block;

                        &Container {
                            display: block;

                            &_noNextSlide {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    p {
        margin-block-end: 0;
    }
}
