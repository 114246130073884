/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductLabel {
    font-size: var(--font-size-normal-small);
    font-weight: 700;
    position: absolute;
    padding: 3px 5px;
    inset-block-start: 0;
    inset-inline-start: 0;
    text-transform: uppercase;

    div {
        display: block;
        position: absolute;
        inset-inline-start: 0;
        border-style: solid;
        border-width: 0 1rem 1rem 0;
        margin-block-start: 3px;
    }

    .ProductActions & {
        position: relative;
        order: -1;
        width: fit-content;
        padding: 3px 15px;
    }

    &-PseidoElement {
        .ProductActions & {
            display: none;
        }
    }

    &-Placeholder {
        .TextPlaceholder {
            &::after {
                display: block;
                width: 60px;
                height: 20px;
            }
        }
    }

    &_BadgeLabel {
        position: relative;
    }
}
