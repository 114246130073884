/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductConfigurableAttributes {
    &-SwatchList {
        margin-block-end: 10px;

        @include mobile {
            gap: 7px;
        }

        .ProductActions & {
            gap: 6px;

            @include mobile {
                padding-inline-start: 1px;
            }
        }
    }

    &-ListItem {
        margin: 0 7px 0 0;
        padding-inline-start: 0;
        display: block;

        &:last-child {
            margin-block-end: 0;
        }

        .TextPlaceholder {
            font-size: 31px;
        }
    }
}
