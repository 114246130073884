/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --wishlist-heart-size: 12px;
    --wishlist-heart-background: #181818;
}

.ProductWishlistButton {
    margin: 0px;
    vertical-align: middle;
    display: inline-block;
    height: 100%;
    width: 50px;

    @include after-mobile {
        margin-block-start: auto;
        margin-inline-start: 8px;
    }

    @include mobile {
        @include isArabic {
            margin-inline-end: 10px;
        }
    }

    &-Button {
        --button-padding: 0;
        --button-hover-background: transparent;
        --button-hover-border: var(--primary-dark-color);

        /* stylelint-disable-next-line declaration-no-important */
        border: 1px solid var(--button-background) !important;
        border-radius: 2px;
        width: 100%;

        @include desktop {
            &:hover {
                --wishlist-heart-background: #fff;
            }
        }

        &_isInWishlist {
            --wishlist-heart-background: #fff;
            --button-hover-background: var(--primary-dark-color);
            --button-hover-border: var(--primary-dark-color);

            &:hover {
                @include after-mobile {
                    --wishlist-heart-background: #fff;
                }
            }
        }

        .ProductActions & {
            height: 48px;

            &:hover,
            &:focus {
                height: 48px;
            }

            &:focus {
                background-color: var(--color-white);

                svg {
                    fill: var(--color-black);
                }
            }

            @include after-mobile {
                &:hover {
                    background-color: var(--color-black);

                    svg {
                        fill: var(--color-white);
                    }
                }
            }

            &_isInWishlist {
                background-color: var(--color-black);

                svg {
                    fill: var(--color-white);
                }

                &:focus {
                    background-color: var(--color-black);

                    svg {
                        fill: var(--color-white);
                    }
                }
            }

            @include mobile {
                height: var(--add-to-cart-btn-height);

                &:hover,
                &:focus {
                    height: var(--add-to-cart-btn-height);
                }
            }
        }
    }

    &-ProductWishlistIcon {
        margin-inline-end: initial;
        fill: var(--wishlist-heart-background);

        @include mobile {
            width: 20px;
            height: 20px;
        }
    }

    &-ButtonText {
        font-family: var(--font-FFDIN);
        font-size: var(--font-size-medium);
        font-weight: bold;
        display: inline-block;

        @include mobile {
            font-size: var(--font-size-small);
        }
    }

    &_isPDPLoading {
        height: 47px;
        width: 50px;

        @include placeholder-animation-with-padding;

        @include mobile {
            height: var(--add-to-cart-btn-height);
        }
    }
}
