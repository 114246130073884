/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --high-price-line-color: var(--color-red);
    --price-color: var(--color-black);
    --price-discount-color: var(--color-black);
}

.ProductPrice {
    color: var(--price-color);
    font-weight: 700;
    vertical-align: middle;
    line-height: 18px;

    @include mobile {
        font-size: 18px;
    }

    ins {
        text-decoration: none;
    }

    del {
        font-size: .7em;
        font-weight: 500;
        opacity: .5;
    }

    &-CurrentPrice {
        font-size: var(--font-size-medium-big);
        font-weight: 700;
        padding-inline-end: 2px;

        @include isArabic {
            padding-inline-start: 2px;
            padding-inline-end: 0;
        }

        @include desktop {
            font-size: var(--font-size-medium);
            padding-inline-end: 4px;

            @include isArabic {
                padding-inline-start: 4px;
                padding-inline-end: 0;
            }
        }

        span {
            font-weight: 700;
            padding-inline-end: 3px;
        }
    }

    &-HighPrice {
        font-size: var(--font-size-normal);
        width: fit-content;
        padding-inline-start: .1em;
        color: var(--price-discount-color);
        display: none;

        @include desktop {
            font-size: var(--font-size-normal-small);
        }

        @include small-desktop {
            font-size: var(--font-size-medium);
        }

        span {
            padding-inline-end: 3px;
        }

        &::before {
            content: '';
            position: absolute;
            transform: translateY(-50%);
            inset-block-start: 50%;
            border-bottom: solid 2px var(--high-price-line-color);
            height: 0;
            width: 100%;
            margin-block-start: -1px;

            @include desktop {
                margin-block-start: 0;
            }
        }

        &_isVisible {
            display: inline-block;
        }
    }

    &-Placeholder {
        &::after {
            content: 'aaaaa';
            display: block;
            width: 120px;
            height: 20px;
        }
    }

    &-PercentageLabel {
        font-size: 9px;
        color: white;
        font-weight: bold;
        background: var(--color-red);
        padding: 2px 7px;
        border-radius: 6px;
        white-space: nowrap;

        @include desktop {
            font-size: 12px;
        }

        .ProductCard & {
            inset-block-start: 4px;
        }
    }
}
